import React, { createContext, useContext, useState, useEffect } from 'react';

import auth from './firebaseconfig';
import { onAuthStateChanged } from 'firebase/auth';
import { getUserInfoApi } from '@/services/userApi';

import defaultUserInfo from '../data/default/UserInfo';



const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const [userLoggedIn, setUserLoggedIn] = useState(!!userInfo.uid);
  console.log(userLoggedIn)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        setUserLoggedIn(true);
        
        // Set basic info from Firebase
        setUserInfo({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          // Add any other user properties you want to store
        });

        // Fetch additional user info from your backend
        try {
          const response = await getUserInfoApi();
          if (response && response.data) {
            setUserInfo(prevInfo => {
              const updatedInfo = {
                ...prevInfo,
                ...response.data
              };
              console.log("Updated userInfo:", updatedInfo);
              return updatedInfo;
            });
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        // User is signed out
        setUserLoggedIn(false);
        setUserInfo(defaultUserInfo);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo, userLoggedIn, setUserLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};