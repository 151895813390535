const defaultUserInfo = {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    is_active: false,
    is_premium_member: null,
    user_type: '',
    last_login: null,
    number_of_queries_made: 0,
    documents: [],
    conversations: [],
}

export default defaultUserInfo;