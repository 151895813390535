import auth from './firebaseconfig';
import { router } from 'next/router';

const TOKEN_EXPIRY_TIME = 3 * 60 * 60 * 1000; // 1 hour in milliseconds


async function getFirebaseToken() {
    console.log("Getting token");

    if (auth.currentUser) {
        console.log("Current user found, attempting to get new token");
        try {
            const newToken = await auth.currentUser.getIdToken(true);  // Force token refresh
            console.log("New token received");
            
            // const expiryTime = Date.now() + TOKEN_EXPIRY_TIME;
            
            // try {
            //     localStorage.setItem('authToken', JSON.stringify({
            //         token: newToken,
            //         expiry: expiryTime
            //     }));
            //     console.log("New token saved to localStorage with expiry");
            // } catch (e) {
            //     console.error("Error saving token to localStorage:", e);
            // }
            
            return newToken;
        } catch (error) {
            console.error("Error getting new token:", error);
            // If we fail to get a new token, fall back to stored token
        }
    }

    // // If we couldn't get a new token, try to use the stored one
    // let storedTokenData;
    // try {
    //     const storedItem = localStorage.getItem('authToken');
    //     storedTokenData = storedItem ? JSON.parse(storedItem) : null;
    // } catch (e) {
    //     console.error("Error accessing or parsing localStorage:", e);
    // }
    
    // if (storedTokenData && storedTokenData.expiry > Date.now()) {
    //     console.log("Using valid token from localStorage");
    //     return storedTokenData.token;
    // } else if (storedTokenData) {
    //     console.log("Stored token has expired");
    //     localStorage.removeItem('authToken');
    // }

   // If we have neither a new token nor a valid stored token
   console.log("No valid token available, redirecting to SignIn");
   return null;
   router.push('/SignIn');  // Redirect to SignIn page
   throw new Error("No valid authentication token available");
}

export default getFirebaseToken;