// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6lk1qTftkSG4ajw_pkrBz7iKvLZeibPY",
  authDomain: "juree-618c2.firebaseapp.com",
  projectId: "juree-618c2",
  storageBucket: "juree-618c2.appspot.com",
  messagingSenderId: "2842156371",
  appId: "1:2842156371:web:4b0eed886ac52d85ccd5a6",
  measurementId: "G-LE4363VPXK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;