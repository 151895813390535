import getFirebaseToken from '@/context/Auth';
// import { headers } from '@/next.config';
import axios from 'axios';

export const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

export const createUserApi = async (first_name, last_name, email, password) => {
    console.log("Creating user", BACKEND_URL+'/api/users/create_new_user')
    const requestHeaders = {
        'Content-Type': 'application/json',
    }
    const response = await axios.post(BACKEND_URL + '/api/users/create_new_user', {
        first_name,
        last_name,
        email,
        password
    }, {
        headers: requestHeaders
    });

    console.log(response);
    return response;
}

export const resendVerificationEmailApi = async (email) => {
    const requestHeaders = {
        'Content-Type': 'application/json',
    }
    try {
        const response = await axios.post(`${BACKEND_URL}/api/users/resend_verification_email`, {
            email: String(email)  // Ensures email is treated as a string
        }, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // Assuming you want to return the data from the server
    } catch (error) {
        console.log(error)
        console.error('Error:', error.response || error);
        return error.response || { error: "Network or server error" };
    }
}

export const getUserInfoApi = async () => {
    console.log("Getting user info")
    const token = await getFirebaseToken();
    console.log("token", token)
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    try {
        const response = await axios.get(`${BACKEND_URL}/api/users/get_user_info`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response;  // Assuming you want to return the data from the server
    } catch (error) {
        console.log("header", requestHeaders)
        console.log(error)
        console.error('Error:', error.response || error);
        return error.response || { error: "Network or server error" };
    }
}

export const getConversationsApi = async () => {
    console.log("Getting conversations");
    const token = await getFirebaseToken();
    console.log("token", token);
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/conversation/`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the conversations array
    } catch (error) {
        console.log("header", requestHeaders);
        console.log(error);
        console.error('Error:', error.response || error);
        return error.response || { error: "Network or server error" };
    }
};

export const getConversationDetailsApi = async (conversationId) => {
    console.log("Getting conversation details for ID:", conversationId);
    const token = await getFirebaseToken();
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  
    try {
      const url = `${BACKEND_URL}/api/conversation/${conversationId}`;
      const response = await axios.get(url, {
        headers: requestHeaders
      });
      console.log('Success:', response);
      return response.data;
    } catch (error) {
      console.error('Error fetching conversation details:', error);
      throw error;
    }
  };

  export const uploadDocumentApi = async (file) => {
    console.log("Uploading document");
    const token = await getFirebaseToken();
    const formData = new FormData();
    formData.append('file', file);

    const requestHeaders = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.put(`${BACKEND_URL}/api/document/upload`, formData, {
            headers: requestHeaders
        });
        console.log('Upload Success:', response);
        return response.data;  // This will return { id, document_title, size_in_kb }
    } catch (error) {
        console.error('Error uploading document:', error);
        throw error;
    }
};

export const createConversationApi = async (documentIds, type) => {
    console.log("Creating conversation");
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    const requestBody = {
        document_ids: documentIds,
        conversation_type: type
    };

    try {
        const response = await axios.post(`${BACKEND_URL}/api/conversation/`, requestBody, {
            headers: requestHeaders
        });
        console.log('Create Conversation Success:', response);
        return response.data;  // This will return { id, documents }
    } catch (error) {
        console.error('Error creating conversation:', error);
        throw error;
    }
};

export const getCareerDocumentsApi = async () => {
    console.log("Getting career documents");
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/document/career_documents`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data.career_docs;  // This will return the career documents object
    } catch (error) {
        console.log("header", requestHeaders);
        console.error('Error:', error.response || error);
        if (error.response && error.response.status === 404) {
            return { error: "No career documents found" };
        }
        return { error: "Network or server error" };
    }
};

export const sendMessageApi = async (selectedConversationId, message) => {
    console.log("Sending message to conversation ID:", selectedConversationId);
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await fetch(`${BACKEND_URL}/api/conversation/${selectedConversationId}/message?user_message_str=${encodeURIComponent(message)}`, {
            method: 'GET',
            headers: requestHeaders,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response; // Return the response object
    } catch (error) {
        console.error('Error sending message:', error.message);
        throw error;
    }
};

export const getForumCategoriesApi = async () => {
    console.log("Getting forum categories");
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }; 

    try {
        const response = await axios.get(`${BACKEND_URL}/api/forum/categories`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the forum categories object
    } catch (error) {
        console.error('Error fetching forum categories:', error);
        throw error;
    }
};

export const getForumCategoryApi = async (categoryId) => {
    console.log("Getting forum category for ID:", categoryId);
    const token = await getFirebaseToken();
    const requestHeaders = {    
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }; 

    try {
        const response = await axios.get(`${BACKEND_URL}/api/forum/categories/${categoryId}`, {
            headers: requestHeaders
        }); 
        console.log('Success:', response);
        return response.data;  // This will return the forum category object
    } catch (error) {
        console.error('Error fetching forum category:', error); 
        throw error;
    }
};  
    

export const getForumPostsApi = async (categoryId, page, perPage) => {
    console.log("Getting forum posts for category ID:", categoryId);
    const token = await getFirebaseToken();
    const requestHeaders = {    
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/forum/categories/${categoryId}/posts?page=${page}&per_page=${perPage}`, {
            headers: requestHeaders
        }); 
        console.log('Success:', response);
        return response.data;  // This will return the forum posts object
    } catch (error) {
        console.error('Error fetching forum posts:', error);
        throw error;
    }
};

export const getForumPostApi = async (postId) => {
    console.log("Getting forum post for ID:", postId);
    const token = await getFirebaseToken();
    const requestHeaders = {    
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/forum/posts/${postId}`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the forum post object
    } catch (error) {
        console.error('Error fetching forum post:', error);
        throw error;
    }
};    

export const getForumPostCommentsApi = async (postId, page, perPage) => {   
    console.log("Getting forum post comments for ID:", postId);
    const token = await getFirebaseToken();
    const requestHeaders = {    
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/forum/posts/${postId}/comments?page=${page}&per_page=${perPage}`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the forum post comments object
    } catch (error) {
        console.error('Error fetching forum post comments:', error);
        throw error;
    }
};  

export const createForumPostApi = async (categoryId, title, content) => {
    console.log("Creating forum post in category ID:", categoryId);
    const token = await getFirebaseToken();
    const requestHeaders = {    
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    const requestBody = {
        "category_id": categoryId,
        "title": title,
        "content": content
    };

    try {
        const response = await axios.post(`${BACKEND_URL}/api/forum/posts`, requestBody, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the created forum post object
    } catch (error) {
        console.error('Error creating forum post:', error);
        throw error;
    }
};

export const createForumPostCommentApi = async (postId, content) => {
    console.log("Creating comment on forum post ID:", postId);
    const token = await getFirebaseToken();
    const requestHeaders = {    
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    const requestBody = {
        "post_id": postId,
        "content": content
    };

    try {
        const response = await axios.post(`${BACKEND_URL}/api/forum/comments`, requestBody, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the created comment object
    } catch (error) {
        console.error('Error creating comment on forum post:', error);
        throw error;
    }
};

export const createCheckoutSessionApi = async () => {
    console.log("Creating checkout session");
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.post(`${BACKEND_URL}/api/checkout/create-checkout-session`, {}, {
            headers: requestHeaders
        });
        console.log('Checkout session created:', response);
        return response.data.clientSecret;
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
};

export const getSessionStatusApi = async (sessionId) => {
    console.log("Checking session status for ID:", sessionId);
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/checkout/session-status?session_id=${sessionId}`, {
            headers: requestHeaders
        });
        console.log('Session status:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error checking session status:', error);
        throw error;
    }
};

export const createManageAccountSessionApi = async () => {
    console.log("Creating manage account session");
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.post(`${BACKEND_URL}/api/checkout/create-customer-portal-session`, {}, {
            headers: requestHeaders
        });
        console.log('Manage account session created:', response);
        return response.data.url;
    } catch (error) {
        console.error('Error creating manage account session:', error);
        throw error;
    }
};  

export const getUserApplicationsApi = async () => {
    console.log("Getting user applications");
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`${BACKEND_URL}/api/tracker/applications`, {
            headers: requestHeaders
        });
        console.log('Success:', response);
        return response.data;  // This will return the user applications object
    } catch (error) {
        console.error('Error fetching user applications:', error);
        throw error;
    }
};

export const updateApplicationApi = async (applicationData) => {
    console.log("Updating application for ID:", applicationData.opportunity_id);
    const token = await getFirebaseToken();
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    
    // Convert date strings to timezone-naive datetime format
    const formatDate = (dateStr) => {
        if (!dateStr) return null;
        const date = new Date(dateStr);
        return `${date.toISOString().split('T')[0]} 00:00:00`; // Returns 'YYYY-MM-DD HH:mm:ss'
    };

    try {
        const response = await axios.put(`${BACKEND_URL}/api/tracker/applications`, 
            [{
                id: applicationData.id,
                opportunity_id: applicationData.opportunity_id,
                user_id: applicationData.user_id,
                has_applied: applicationData.has_applied,
                applied_date: formatDate(applicationData.applied_date),
                has_interview: applicationData.has_interview,
                interview_date: formatDate(applicationData.interview_date),
                has_offer: applicationData.has_offer,
                offer_date: formatDate(applicationData.offer_date),
                notes: applicationData.notes
            }],
            {
                headers: requestHeaders
            }
        );
        console.log('Success:', response);
        return response.data;
    } catch (error) {
        console.error('Error updating application:', error);
        throw error;
    }
};
