import { useState, useEffect } from 'react';

const MobileBanner = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  if (!isClient || !isMobile) return null;

  return (
    <div className="mobile-banner-alert">
      <div className="inner">
        <div className="content">
          <span className="alert-text">
            <i className="feather-info mr--5"></i>
            For the best experience, please use the Juree desktop site.
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileBanner;